import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useClearTimeout from 'hooks/useClearTimeout'
import useWindowSize from 'hooks/useWindowSize'

import Link from 'components/Link'
import SliderBlock from 'components/SliderBlock'
import CloudinaryMedia from 'components/CloudinaryMedia'

import s from './SliderCover.module.css'

function SliderCover({ covers, coverTitle, noLink, className }) {
  if (covers?.length < 0) return null

  const router = useRouter()
  const [progress, setProgress] = useState(0)
  const [timeoutId, setTimeoutId] = useState(null)
  const [locationTimeoutId, setLocationTimeoutId] = useState(null)
  const [videoTimeoutId, setVideoTimeoutId] = useState(null)
  const [sliderColour, setSliderColour] = useState('')
  const [sliderMobileColour, setSliderMobileColour] = useState('')
  const sliderRef = useRef(null)
  const setSliderLength = covers.length
  const windowSize = useWindowSize()
  const isDesktop = windowSize?.width >= 900
  const isMobile = windowSize?.width < 900

  useClearTimeout(timeoutId)
  useClearTimeout(locationTimeoutId)
  useClearTimeout(videoTimeoutId)

  useEffect(() => {
    const doc = document.documentElement
    const headerAttr = 'data-header-color'

    if (
      (isMobile &&
        (router.pathname === '/[site]/[boardType]' ||
          router.pathname === '/[site]/[boardType]/[boardSlug]')) ||
      (isMobile && sliderMobileColour === 'dark')
    ) {
      doc.setAttribute(headerAttr, 'dark')
    } else if (
      (isMobile && sliderMobileColour === 'light') ||
      (isDesktop && sliderColour === 'light')
    ) {
      doc.setAttribute(headerAttr, 'light')
    } else if (isDesktop && sliderColour === 'light-dark-logo') {
      doc.setAttribute(headerAttr, 'light-ui-dark-logo')
    } else if (isDesktop && sliderColour === 'dark-light-logo') {
      doc.setAttribute(headerAttr, 'dark-ui-light-logo')
    }

    return () => {
      doc.removeAttribute(headerAttr)
    }
  }, [isMobile, sliderColour, sliderMobileColour])

  useEffect(() => {
    //TODO: Quickly added a random timeout to try and play videos
    // after a location change. Need to find a proper way to resolve
    // when time is allocated
    return () => {
      const locationTimeoutId = setTimeout(() => {
        playVideo()
      }, 2000)
      setLocationTimeoutId(locationTimeoutId)
    }
  }, [router])

  const sliderSettings = {
    fade: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    onInit: () => {
      // NOTE: Arbitrary timeout set here. Can we get the video before slider
      // init as it is firing before?
      const timeoutId = setTimeout(() => {
        playVideo()
      }, 1000)
      setTimeoutId(timeoutId)
      if (covers?.[0].acfHighlightCover?.covers?.length) {
        const { uiColour, uiColourMobile } =
          covers?.[0].acfHighlightCover?.covers?.[0]
        setSliderColour(uiColour)
        setSliderMobileColour(uiColourMobile)
      }
    },
    beforeChange: (oldIndex, newIndex) => {
      setProgress((newIndex / (setSliderLength - 1)) * 100)
      if (covers?.[newIndex]?.acfHighlightCover?.covers?.length) {
        const { uiColour, uiColourMobile } =
          covers?.[newIndex].acfHighlightCover?.covers?.[0]
        setSliderColour(uiColour)
        setSliderMobileColour(uiColourMobile)
      }
    },
    afterChange: (index) => {
      playVideo()
    },
  }

  // NOTE: We should really be using refs instead of query selector
  // to acceess videos.
  const getVideo = (video) => {
    if (video) {
      const promiseVideo = video.play()
      if (promiseVideo !== undefined) {
        promiseVideo
          .catch((error) => {
            console.log('video error: ', error)
          })
          .then(() => {
            sliderRef?.current?.slickPause()
          })
      }
    }
  }

  const playVideo = () => {
    if (typeof window !== 'undefined') {
      const videos = document.querySelectorAll(
        '.slick-slide.slick-active video'
      )
      getVideo(videos[0], !isDesktop)
      getVideo(videos[1], isDesktop)
    }
  }

  const handleVideoEnded = () => {
    if (typeof window !== 'undefined') {
      const videos = document.querySelectorAll(
        '.slick-slide:not(.slick-active) video'
      )

      // SEL-26 - Some how one user that seems to have this blinking page open forever is now killing sentry because videos.forEach() doesn't exist
      if ((!videos) instanceof NodeList) {
        return
      }
      const videoTimeoutId = setTimeout(() => {
        videos.forEach((video) => {
          video.load()
        })
      }, 1000)
      setVideoTimeoutId(videoTimeoutId)
    }
    sliderRef?.current?.slickNext()
    sliderRef?.current?.slickPlay()
  }

  const setLink = (slug, children, decideLink = false) => {
    return !decideLink && !noLink && slug ? (
      <Link type="Highlight" href={slug} className={s.link}>
        {children}
      </Link>
    ) : (
      children
    )
  }

  const decideMedia = (videos, images, className) => {
    return (
      <CloudinaryMedia
        className={classNames(s.media, className)}
        videos={videos}
        videoAutoplay={false}
        videoEnded={() => handleVideoEnded()}
        images={images}
        noLazy
      />
    )
  }

  const setContent = (
    videosMobile,
    videosDesktop,
    images,
    title,
    description,
    slug = '',
    decideLink,
    gender = '',
    modelSlug = ''
  ) => {
    return (
      <div key={`${description}_${title}_${slug}`} className={s.slide}>
        {setLink(
          slug,
          <>
            {/* TODO: Quickly added as we needed a Web version from CMS and
            not Cloudinary generated. Need time to refactor and consider mobile/desktop loading
            for performance. You will also notice when a slide is just using images it will have a
            mobile and desktop version when really we can just use one <picture> to handle this. */}
            {videosMobile && decideMedia(videosMobile, images, s.mediaMobile)}
            {videosDesktop &&
              decideMedia(videosDesktop, images, s.mediaDesktop)}
          </>,
          decideLink
        )}
        <div className={s.info}>
          {title && modelSlug && (
            <Link type="Model" href={modelSlug} className={s.link}>
              <h2
                className={s.title}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </Link>
          )}
          {description &&
            setLink(
              slug,
              <p
                className={s.description}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />,
              decideLink
            )}
        </div>
      </div>
    )
  }

  const setVideos = (mobile, desktop) => {
    const mobileUrl = mobile?.mediaItemUrl
    const mobileType = mobile?.mimeType
    const desktopUrl = desktop?.mediaItemUrl
    const desktopType = desktop?.mimeType
    return [
      mobileUrl
        ? {
            url: mobileUrl,
            type: mobileType,
            // params: 'f_auto,vc_auto,ac_none',
          }
        : undefined,
      desktopUrl
        ? {
            url: desktopUrl,
            type: desktopType,
            // params: 'f_auto,vc_auto,ac_none',
          }
        : undefined,
    ].filter((x) => x !== undefined)
  }

  const setImages = (mobile, desktop) => {
    const mobileUrl = mobile?.mediaItemUrl
    const desktopUrl = desktop?.mediaItemUrl
    return [
      mobileUrl ? { url: mobileUrl, params: 'f_auto' } : undefined,
      desktopUrl
        ? {
            url: desktopUrl,
            params: 'f_auto',
          }
        : undefined,
    ].filter((x) => x !== undefined)
  }

  return (
    <div className={classNames(s.container, className)}>
      <SliderBlock
        parentRef={sliderRef}
        setKey={router.query?.boardSlug}
        settings={sliderSettings}
      >
        {covers?.map(
          ({ acfBlocks, acfHighlightCover, acfHighlightSettings, slug }, i) => {
            if (
              !acfHighlightCover?.covers ||
              acfHighlightCover?.covers?.length < 1
            )
              return null
            const {
              videoMobile,
              videoMobileWebm,
              videoDesktop,
              videoDesktopWebm,
              imageMobile,
              imageDesktop,
            } = acfHighlightCover.covers[0]
            const title = acfHighlightSettings?.models?.title
            const modelSlug = acfHighlightSettings?.models?.slug
            const description = acfHighlightCover?.shortDescription
            const decideLink = acfBlocks.contentBlocks?.length < 1
            const gender = acfHighlightSettings?.models?.acfModel?.gender

            return setContent(
              setVideos(videoMobileWebm, videoMobile),
              setVideos(videoDesktopWebm, videoDesktop),
              setImages(imageMobile, imageDesktop),
              title,
              coverTitle || description,
              slug,
              decideLink,
              gender,
              title ? modelSlug : null
            )
          }
        )}
      </SliderBlock>
      {covers.length > 1 && (
        <div className={s.progressBar}>
          <div
            className={s.progressBarIndicator}
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  )
}

SliderCover.propTypes = {
  covers: PropTypes.array,
  coverTitle: PropTypes.string,
  noLink: PropTypes.bool,
  className: PropTypes.string,
}
SliderCover.defaultProps = {
  covers: [],
  coverTitle: '',
  noLink: false,
  className: '',
}

export default SliderCover
